import * as React from "react";
import {useState} from "react";
import {useFirestore, useFirestoreCollectionData, useFirestoreDocDataOnce, useUser} from "reactfire";
import {GCUser} from "../user";
import {LoadingSpinner} from "../display/LoadingSpinner";

type Chart ={
    title: string;
    client: string;
    description: string;
    dob: string;
    contact?: string;
    emergency?: string;
    insurance?: string;
    advisor?: string;
    createdBy?: string;
    createdDate: Date;
    lastModified?: Date;
    objectives: any;
    history: any;
    attributes: any;
    defaultView: string // one of "objectives" | history | timeline ...
}
export const NewChart = () => {
    const firestore = useFirestore();
    const { data: user } = useUser();
    let myUser = new GCUser(user);
    const userProfile = useFirestoreDocDataOnce(myUser.storedDetailsDoc(firestore),{idField: 'uid'});
    myUser = myUser.initializeUser(userProfile);

    const itemsCollection = myUser.myChartsCollection(firestore);
    const templatesCollection = firestore.collection('templates/');
    const [title,updateTitle] = useState('');
    const [client,updateClient] = useState('');
    const [desc,updateDesc] = useState('');
    const [advisor,updateAdvisor] = useState(myUser.isTrusted() ? myUser.email : 'No Advisor Yet');
    const [dob, updateDOB] = useState('');
    const [contact,updateContact] = useState('');
    const [econtact,updateEContact] = useState('');
    const [insurance,updateInsurance] = useState('');
    const templatesQuery = templatesCollection.orderBy('name',  'desc');
    const { status, data: templates } = useFirestoreCollectionData(templatesQuery, {idField: 'id'});

    //const localCollection = firestore.collection('charts')
    const today = new Date();
    let myTemplates: string [] = [];
    const createChart = (attrs) => {
        const chart: Chart = {title: title, client: client, dob: dob, contact: contact, emergency: econtact, insurance: insurance, description: desc, createdDate: today, attributes: {}, objectives: {}, history: {}, defaultView: 'objectives'};
        chart.advisor = advisor;
        chart.createdBy = user.uid;
        chart.attributes = attrs;
        return chart;
    }
    const saveChart = (chart) => {
        console.log('creating new chart in:' + itemsCollection);
        chart.lastModified = new Date;
        const chartPromise = itemsCollection.add(chart);
        return chartPromise;
    }
    const handleSubmit = () => {
        saveChart(createChart({})).then((chartRef) => {
            console.log('created new chart');
            myTemplates.forEach((template) => {
                const templateItemsCollection = templatesCollection.doc('/' + template).collection('/items');
                initilizeChartWithTemplate(chartRef,templateItemsCollection);
            })
            updateTitle('');
            updateClient('');
            updateDesc('');
        })
    }
    const toggleTemplate = (templateID,toggle) => {
        let indx = templates.indexOf(templateID);
        if (indx === -1) {
            if(toggle) myTemplates.push(templateID);
        } else {
            if(!toggle) myTemplates.splice(indx,1);
        }

    }
    return (
        <div className="mt-1 max-w-prose">
            <form onSubmit={(event) => {event.preventDefault(); handleSubmit();}}>
                <label htmlFor="ctable" className="block text-lg text-gray-700 text-center font-bold">
                    Create A New Chart
                </label>
                <table className="min-w-full divide-y divide-gray-200" id='ctable'>
                <tbody className="bg-white divide-y divide-gray-200">
                <tr>
                    <th>Client</th>
                    <td><input type="text" value={client} onChange={(event) => updateClient(event.target.value)}/> </td>
                </tr>
                <tr>
                    <th>Date of Birth</th>
                    <td><input type="text" value={dob} onChange={(event) => updateDOB(event.target.value)}/> </td>
                </tr>
                <tr>
                    <th>Contact Info</th>
                    <td><input type="text" value={contact} onChange={(event) => updateContact(event.target.value)}/> </td>
                </tr>
                <tr>
                    <th>Emergency Contact Info</th>
                    <td><input type="text" value={econtact} onChange={(event) => updateEContact(event.target.value)}/> </td>
                </tr>
                <tr>
                    <th>Insurance Info</th>
                    <td><input type="text" value={insurance} onChange={(event) => updateInsurance(event.target.value)}/> </td>
                </tr>
                <tr>
                    <th>Care Advisor</th>
                    <td><input type="text" value={advisor} onChange={(event) => updateAdvisor(event.target.value)}/> </td>
                </tr>
                <tr>
                    <th>Initial Status</th>
                    <td><textarea id="desc" name="desc" rows={2}
                                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                                  placeholder="Notes related to client " value={desc} onChange={(event) => updateDesc(event.target.value)} /> </td>
                </tr>
                </tbody>
                </table>

                <p className="ml-2 mt-5">Select template(s) below to add the associated template items into the new chart</p>
                {status === 'loading' ? <LoadingSpinner /> : <ul>
                    {templates.map((t)=> {return <TemplateCheckbox iid={t.id} label={t.name} selectFn={toggleTemplate} />})}
                </ul>}
                <span className="flex justify-end">
                    <input type="submit" disabled={!client || !dob} value="Create Chart" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full right-full"/>
                </span>
            </form>
        </div>

    )

}

const TemplateCheckbox = ({iid, label, selectFn}) =>{
    const [selected,toggle] = useState(false);
    const clickFN = () => {
        toggle(!selected);
        selectFn(iid,selected);
    }
    return <li className="py-3 text-bold text-gray-800 ml-4" onClick={()=>{clickFN()}}>  <input type="checkbox"  checked={selected}  />  {label} </li>
}

export function itemFromTemplate(temp){
    let newItem = {title: temp.title, desc: temp.desc, createdBy: 'template', color: '', createdDate: new Date()};
    if(temp.color) newItem.color = temp.color;
    //TBD check and add attributes here
    return newItem;

}

function initilizeChartWithTemplate(chartRef,templateItemsCollection){
    const collection = chartRef.collection('/items');
    templateItemsCollection.get()
        .then((tItems => {
            tItems.foreach((doc) => {
                collection.add(itemFromTemplate(doc));
            })
        }))

}