import * as React from "react";
import {useState} from "react";

export const COLORS = ['green','gold','red'];
export const ColorMap = {green: "#10B981", red: "#EF4444", yellow: "#FCD34D"} //yellow is too light using gold instead
export const getColor = (c) => ColorMap[c] ? ColorMap[c] : c;
export const Stoplight = ({color, colors = COLORS, setColor = (c) => {}}) => {
    if(!colors){ colors = COLORS};

    const mywidth = colors.length * 1.2;
    return (
        <>
            <svg width={String(mywidth) + 'em'} height="1.2em" display="inline">
                {colors.map((c, i) => (
                    <circle key={i} cx={String(i*1.2 + .55)+"em"} cy=".5em" r=".5em" stroke={getColor(c)} fill={getColor(c)}
                            fillOpacity={c == color ? 1.0 : 0.2} onClick={(e)=>setColor(c)}/>
                ))
                }
            </svg>
        </>
    )
}

