import * as React from 'react';
import {
    useFirebaseApp,
    useFirestore,
    useFirestoreCollectionData,
    useFirestoreDocData,
    useFirestoreDocDataOnce,
    useUser
} from 'reactfire';
import {GCUser, validateEmail, invite} from "../user";
import {useState} from "react";


export const addMember = (chart, guser : GCUser, role='viewer') => {
    const chartID = chart.id;
    const firestore = useFirestore;
    const ref = firestore().collection('charts/' + chartID + '/accessors')
    const member = {
        email: guser.email,
        initials: guser.getInitials(),
        name: guser.name,
        role: role,
        addedDate: new Date(),
        uid: guser.uid
    }
    //let result = ref.add(member);  //TBD below is a problem if we don't have uid
    let result = ref.doc(guser.uid).set(member) //though UID should be something we assign rather than google
    guser.subscribe(chart, firestore())
    return result;
}


export const inviteMember = (chart, myEmail : string, inviter : GCUser, firestore) => {

    let result = invite(myEmail,chart,firestore, inviter)
    return result;
}
export function saveUpdateToTeam(update,team, firestore ){
    var lastPromise;
    if(!team) return;
    for(let member of team){
        let itemID = update.prevItem.id;
        let updatesCollection  =  firestore.collection('updates/' + member.uid +'/items');
        lastPromise = updatesCollection.doc(itemID).set(update);  //should keep track of the promises in case things fail...
    }
    return lastPromise
}

export const TeamMembers = ({chart, orgsOnly, show}) => {
    const chartID = chart.id;
    const firestore = useFirestore();
    const ref = firestore.collection('charts/' + chartID + '/accessors')
    const teamQuery = ref; //.orderBy('addedDate',  'asc'); //probably should be lastModified date
    const { status, data: team } = useFirestoreCollectionData(teamQuery, {idField: 'uid'});
    const { data: user } = useUser();
    let myUser = new GCUser(user);
    const userProfile = useFirestoreDocDataOnce(myUser.storedDetailsDoc(firestore),{idField: 'uid'});
    myUser = myUser.initializeUser(userProfile);
    let newUser = true;
    let m : {email: string};
    if (status === 'loading') {return (<div> <p> Loading Team</p>) </div>)};
    let orgs = new Set();
    team.forEach((m) => {
        if(m.email == myUser.email) newUser = false; //should be uid
        if(m.email) {
            // @ts-ignore
            let [u,org] = m.email.split('@',2)
            let [o] = org.split('.',1)
            if(org) orgs.add(o);
        }
    })
    if (newUser) addMember(chart,myUser);

    if (!show) return <></>;
    if (orgsOnly) {
        let orgArray = Array.from(orgs);
        return <span>
            {orgArray.map((k : string) => (<>&nbsp; <span className="bg-gray-100">{k}</span> </>))}
        </span>
            }
    //TBD add input box for new team member (e.g. to share with)
    return (
        <div className="mt-2">
            <h2 className="inline  mr-3">Team: </h2>
            {team.map((member : {uid: string; initials?:string; name?: string; role?: string}) => (
                <div key={member.uid} className="inline mr-3">
                    <TeamMember color='bg-blue-400'
                                initials={member.initials ? member.initials : member.name?.slice(0,2)}
                    /> &nbsp; 
                    <span className="inline">{member.name} </span>
                    <span className="hidden inline">{member.role} </span>
                </div>
            ))}
            <AddTeamMember chart={chart} myUser={myUser} />

        </div>
    )
}

export const AddTeamMember = ({chart, myUser}) => {
    const firestore = useFirestore();
    const [newEmail, setEmail] = useState('');
    const [isEditing, setEditing] = useState(false);
    const registerMember = () => {
        if(!newEmail || !validateEmail(newEmail)) {alert('Invalid email address: ' + newEmail)}
        else {
            inviteMember(chart, newEmail, myUser, firestore);
            setEditing(false);
            setEmail('added '+ newEmail);

        }
    }
    return <>
        <input type='text' className='bg-gray-100 ml-1 float-right' value={newEmail} placeholder="Add email"
               onChange={(e) => {
                   setEmail(e.target.value);
                   setEditing(true);
               }} />
        {isEditing && validateEmail(newEmail) ? <button type="button" className="float-right bg-blue-500 hover:bg-blue-700 text-white font-bold px-3 rounded-full"
                             onClick={() => {registerMember()}} >Invite</button> : <></>}

    </>
}

export const TeamMember = ({initials, color}) => {
    return (
        <span className={color + ' rounded  h-8 w-8 px-1 py-1 inline items-center justify-center inline'}>
            {initials}</span>
    )
}

export const TeamMemberBlank = () => {
    return (
        <span className="rounded  h-8 w-8 bg-gray-50 items-center justify-center inline">
            --</span>
    )
}