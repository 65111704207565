
const TRUSTED = ['unamesa.org','daybreakcenters.org', 'sages.com'];

function isTrusted(org: string | null | undefined):boolean {
    return !org ? false : TRUSTED.includes(org);  // Obviously list of trusted orgs should be pulled from databse...
}

export function invite(myEmail, chart, firestore, inviter){
    const invites = firestore.collection('invites/' + myEmail + '/charts')
    let invitation = {chartID: chart.id, client: chart.client, invitedBy: inviter.name, invitedByEmail: inviter.email, createdDate: new Date()}
    return invites.doc(chart.id).set(invitation)
}


export class GCUser {
    name: string | null | undefined;
    email: string ;
    org: string | null | undefined;
    uid: string | undefined;
    gcProfile: {name?: string | undefined | null } = {};

    constructor(user) {
        //initial an advisor -- e.g. care manager or other provider from a logged in user object
        this.name = user?.name ? user.name : user?.displayName ;
        this.email = user?.email;
        this.org = orgFromEmail(this.email);
        this.uid = user?.uid;
    }

    getInitials() {
        let [first='?' , last='?'] = this.name ? this.name.split(' ',2) : [];
        return (first[0] + last[0])
    }

    storedDetailsDoc(firestore) {
        const collection =firestore.collection(`orgs/${this.org}/users`);
        return collection.doc(this.uid);
    }
    initializeUser(docData): GCUser {
        if(isTrusted(this.org)){
            return new GCAdvisor(this).initializeUser(docData);
        }
        this.gcProfile = docData;
        return this;
    }
    storeUser(firestore){
        const doc = this.storedDetailsDoc(firestore);
        this.gcProfile.name = this.name;
        //should add other info here
        doc.set(this.gcProfile,{merge: true});
    }
    myChartsCollection(firestore) {
        //returns an array listing the id's of charts
        // const result = [];
        //get the chart collection here
        if (this.email === 'wolff@sages.com') {
            return firestore.collection('charts')
        } // special case to see all charts for testing
        return this.mySubscriptions(firestore);

        //we can't do the resolution here because then the listing won't update in real time....instead do it in the chart list
    }


    subscribe(chart, firestore) {
        const subCollection = firestore.collection('subscriptions/'+this.uid + '/charts');
        const subscription = {chartID: chart.id, client: chart.client, subscribedOn: new Date()}
        return subCollection.doc(chart.id).set(subscription);
    }
    mySubscriptions(firestore) {
        return firestore.collection('subscriptions/'+this.uid + '/charts')
    }
    myChartInvites(firestore) {
        let data = this.email;
        //now do text encoder then hash
        //const digest = window.crypto.subtle.digest('SHA-256', this.email);
        return firestore.collection('invites/'+this.email + '/charts')
    }
    myUpdates(firestore) {
        //return firestore.collection('updates/test/updates') //TBD customize to specific user
        //alert('getting user stream updates/' + this.uid +"/items")
        return firestore.collection('updates/'+this.uid+'/items')
    }

    isTrusted(): boolean {
        return false;
    }

    canUpdateChart(update) {
        if(this.email == update.advisor) return true;
        if(this.email == update.topicOwner) return true;
        return false;
    }

}

function orgFromEmail(email: string | undefined | null): string | undefined {
    if(!email) return undefined;
    let user, org;
    try{
        [user,org] = email.split('@');
    } catch (e) {
        console.log('invalid email '+email);
        return undefined
    }
    return org;
}

export class GCAdvisor extends GCUser {
    constructor(user) {
        super(user);
    }
    isTrusted(): boolean {
        return true; //should double check that org matches a trusted org
    }
    initializeUser(docData): GCUser {
        this.gcProfile = docData;
        return this;
    }

}

export const validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
}
