import * as React from "react";
import {Key, useState} from "react";
import {useFirestore, useFirestoreCollectionData, useFirestoreDocData} from "reactfire";
import {saveUpdateToTeam} from "./Team";
import {createNewUpdate, EditUpdate, updateItem} from "./ChartUpdate";
import {COLORS, Stoplight} from "./Stoplight";
import {Link} from "react-router-dom";
import {GoEntry, parseTextAsItem} from "./GoItem";
import {LoadingSpinner} from "../display/LoadingSpinner";
import firebase from "firebase";

type memberStatus = {color: string, entry: string, lastModified: firebase.firestore.Timestamp};
interface TeamStatusSummary {
    [index: string]: memberStatus;
}
// TBD create correct Type for items....this is only a partial list of elements of items....just the things we might modify when updating
export type iType = {
    id?: Key;
    title: string;
    desc: string;
    color: string;
    clientID?: string;
    clientName?: string;
    lastModified: Date;
    updateCount?: number;
    teamStatus?: TeamStatusSummary;  // This allows us to have an object that has one property for each team member that has added a status update
}
// TBD TBD Need to keep track of current item using hash chain...then before saving an update make sure that the item
// has not changed...if the item has changed, we need to warn the user so they don't miss an update.

export const AddUpdate = ({ item, mUser, color, setIsUpdating }) => {
    const chartID = item.clientID;

    const [draftUpdate, setDraftUpdate] = useState("");
    const [statusLight, setStatusLight] = useState(color);

    const firestore = useFirestore();
    const itemsCollection = firestore.collection('charts/' + chartID + '/items')  //Should be set up to auto update
    const teamCollection = firestore.collection('charts/' + chartID + '/accessors')
    const teamQuery = teamCollection.orderBy('name',  'desc');
    const selectedRef = itemsCollection.doc(item.id);
    const updatesCollection = selectedRef.collection('updates')  //Should be set up to auto update
    // typescript syntax for adding types on desstructuring assignments is a bit weird but follows the form below...status should probably be a string
    const {status: currentItemStatus, data: currentItem } : {status: unknown, data: iType} = useFirestoreDocData(selectedRef) ;
    const oldItem = Object.assign({}, currentItem);
    const colors = item.colors ? item.colors : COLORS ;
    const {status: teamStatus, data: teamMembers } = useFirestoreCollectionData(teamQuery, {idField: 'uid'});

    if (teamStatus === 'loading') {
        // should wait here for status to finish
    }
    if (currentItemStatus === 'loading') {return <LoadingSpinner />}

    const saveUpdate = (update) => {
        update.author = mUser.email;
        update.authorID = mUser.uid;
        update.clientID = chartID;
        update.clientName = item.clientName;
        // Need to figure out how to retrieve old item to properly update it
        // const oldItem = firestore.getDoc(selectedRef); useFirestoreDocDataOnce(selectedRef, {idField: 'id'});
        //const oldItem = selectedRef.getDoc({idField: 'id'});
        update.prevItem = oldItem;
        //now save as an update on the item
        updatesCollection.add(update);

        //sets the current update for this item on all team members
        //alert("TBD save update here to all team plus the collection of updates on item")
        if (teamStatus && teamStatus != 'loading') {saveUpdateToTeam(update,teamMembers,firestore)} else {alert('unable to save updates to team members')}
    }


    const saveItem = () => {
        //This function is duplicated between here and GoItem....for no good reason other than original creation of an item uses GoItem to create the first "update"
        currentItem.lastModified = new Date; //caution relies on client date function....
        if(currentItem.color != statusLight){
            currentItem.color = statusLight;
        }
        currentItem.desc = draftUpdate;
        const newUpdate = createNewUpdate(currentItem,draftUpdate);
        let [parsedItem, parsedAttr] = parseTextAsItem(draftUpdate);  //maybe should do this before creating new update if we want the attrs stored for this update...
        updateItem(currentItem,parsedAttr);
        saveUpdate(newUpdate);
        let iCount = currentItem.hasOwnProperty('updateCount') && currentItem.updateCount ? currentItem.updateCount : 0;
        currentItem.updateCount = iCount + 1;
        selectedRef.update(currentItem).then(() => setIsUpdating(false)); //use .then() if we need to do anything after updating ..or catch error
        //setIsUpdating(false);
    }

    return (
        <div className="max-w-7xl">
            <div className="w-full py-1 px-6 bg-white shadow-lg rounded-lg my-5 flex">
                <div className="flex-none justify-center md:justify-center py-2">
                    <Stoplight color={statusLight} colors={colors} setColor={setStatusLight}></Stoplight>
                    <div className="py-2">
                        <Link to={`/updates/${item.clientID}`} className="text-xl font-medium text-indigo-600 hover:text-indigo-900 "> {(item.clientName)} </Link>
                    </div>
                </div>
                <div className="flex-auto px-5 py-1">
                    <h2 className="text-gray-800 text-xl font-semibold">{item.title}</h2>
                    <p className="mt-2 text-gray-600"><UpdateText desc={draftUpdate} placeholder={currentItem.desc} updateFn={setDraftUpdate} /></p>
                    <p className="mt-2 text-gray-500">{currentItem.desc}</p>
                    <div className="flex justify-end mt-4">
                        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-2 rounded-full" onClick={saveItem}>Save</button>

                    </div>
                </div>
            </div>


        </div>
    )
}

// CAUTION if the update text changes outside of this function (e.g. someone else updates the item in a different browser window) those changes will not be shown here
export const UpdateText = ({desc, updateFn, placeholder = 'Double click to add note'}) => {
    const [text,updateText] = useState(desc);
    return <textarea className="text-sm text-gray-900 min-w-full bg-gray-100"
                     autoFocus={true} value={text}
                     onChange={(e)=> updateText(e.target.value)}
                     onBlur={(e) => {
                         updateFn(e.target.value);
                     }}
    />
};
