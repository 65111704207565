import * as React from 'react';
import {
    useFirebaseApp,
    useFirestore,
    useFirestoreCollectionData,
    useFirestoreDocData,
    useFirestoreDocDataOnce,
    useUser
} from 'reactfire';
import {LoadingSpinner} from "../display/LoadingSpinner";
import {AuthWrapper} from "../withoutSuspense/Auth";
import {useParams, useHistory} from "react-router-dom";
import {useEffect, useState } from "react";
import {Link} from "react-router-dom";
import {EditBox} from "./EditBox";
import {GCUser} from "../user";

export const NewTemplateButton = (props) => {
    let { chartID } = useParams();
    if (!chartID && props.chartID) {chartID = props.chartID};
    return <div className="mt-10 ">
        <Link to={"/newtemplate/"+chartID} className="lg:font-medium text-gray-700 py-2 px-2 hover:bg-indigo-600 hover:text-white hover:text-base rounded-md transition duration-150 ease-in-out">
        + Create Template
        </Link>

    </div>
}

function templatize(item){
    //creates a template item from an existing item
    return {title: item.title, desc: item.desc} // If there are any attributes we should add those here
}

function createTemplate(user, items){

    let templateItems = items.map(templatize)
    const templateHeader =  {id: '', name: "Template " + new Date().toLocaleDateString(), authorID: user.uid, authorEmail: user.email}
    return [templateHeader, templateItems] ;
}

function TopicsTemplateWrapper({ chartID }) {
    const firestore = useFirestore;
    const [templateName, setTemplateName] = useState('');
    const ref = firestore().doc('charts/' + chartID);
    const response = useFirestoreDocData(ref, {idField: 'id'});
    const history = useHistory();

    //const { status, data: chart } = response;
    const templatesCollection = firestore().collection('templates/');
    const itemsCollection = firestore().collection('charts/' + chartID + '/items') //This should be set up to auto update
    const itemsQuery = itemsCollection.orderBy('createdDate',  'desc');
    const { status, data: items } = useFirestoreCollectionData(itemsQuery, {idField: 'id'});
    const { data: user } = useUser();
    let myUser = new GCUser(user);
    const userProfile = useFirestoreDocDataOnce(myUser.storedDetailsDoc(firestore()),{idField: 'uid'});
    myUser = myUser.initializeUser(userProfile);
    if (status === 'loading') {
        return <LoadingSpinner />;
    }
    let [templateHeader, templateItems] = createTemplate(myUser, items);
    if(! templateName) setTemplateName(templateHeader.name);
    const saveTemplate = () => {
        let success=false;
        if(templateName) {templateHeader.name = templateName};
        templatesCollection.add(templateHeader)
            .then((tempRef) => {
                const tCollection = tempRef.collection('/items');
                templateItems.map((i)=>tCollection.add(i));
                success=true;
                if(success) history.push('/newchart'); //caution because we are not waiting on the items to be added this may cause errors saving template items TBD
                }
            )
            .catch((err)=>{
                success=false;
                alert('Error saving template'+err);
                }
            )


    }
    return <div className="flex flex-col" id="chartTable">
        <div className="text-center text-gray-700 bg-yellow-200 font-medium">
            <p>
                <span className="text-2xl font-bold text-gray-900">Template: <EditBox txt={templateName} updateFn={setTemplateName} placeholder="MyTemplateName" /></span>

                <button type="button" className="text-lg float-right bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" onClick={() => history.push("/")}>
                    Home
                </button>



            </p>


        </div>


        <div id="mainWrapper" className="flex">
            <div className="flex-1">

                <TemplateTable templateHead={templateHeader} templateItems={templateItems}/>
            </div>
        </div>
        <button type="button" className="text-lg float-right bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" onClick={() => saveTemplate()}>
            SaveTemplate
        </button>
    </div>
}

function TemplateTable({templateHead, templateItems}) {
    const [selectedItemID, selectItem] = useState('');

    const firestore = useFirestore();

    const unselectItem = () => {selectItem('');}
    return (
        <div className="">

            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="shadow border-b border-gray-200 sm:rounded-lg">

                        <table className="w-full max-w-full divide-y divide-gray-200 ">
                            <thead className="bg-gray-100">
                            <tr>

                                <th
                                    scope="col"
                                    className="w-44 max-w-44 px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                    Topic
                                </th>
                                <th scope="col"
                                    className="w-14 px-4 py-2 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >

                                </th>

                                <th
                                    scope="col"
                                    className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                    Description
                                </th>

                            </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">


                            {templateItems.map((item) => (
                                <TemplateItem key={item.id} item={item}  selectedItemID={selectedItemID}  selectItem={selectItem} />
                            ))}
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
        </div>
    )
}
// TBD add save button above that saves the template (with a new id)
// TBD add editing function for a template

const TemplateItem = ({item, selectedItemID, selectItem}) => {
    //TBD make these things editable
    return <tr className="py-3">
        <td className="px-3 py-1 font- whitespace-nowrap font-bold"><EditBox txt={item.title} updateFn={(val)=>{item.title=val}} placeholder="Topic title"/></td>
        <td></td>
        <td className="text-sm text-gray-500"><EditBox txt={item.desc} updateFn={(val)=>{item.desc=val}} placeholder="Double click to add description" /></td>
    </tr>
}

export const NewTemplate = (props) => {
    let { chartID } = useParams();
    if (!chartID && props.chartID) {chartID = props.chartID};
    return (
        <>
            <AuthWrapper fallback={<span>Sign in to view this chart</span>}>

                <div id={chartID}>
                    <TopicsTemplateWrapper chartID={chartID} />

                </div>
            </AuthWrapper>
        </>
    );
};
