import * as React from 'react';
import {
    FirebaseAppProvider,
    useAuth,
    useFirestore,
    useFirestoreDocData,
    useFirestoreDocDataOnce,
    useSigninCheck,
    useUser
} from 'reactfire';
import { Card } from '../display/Card';
import { Auth } from '../withoutSuspense/Auth';
import { Firestore } from '../withoutSuspense/Firestore';
import { Chart, ChartUpdates } from './Chart';
import { TextChart } from './TextChart';
import { ChartList } from './ChartList';
import {
    BrowserRouter as Router,
    Switch,
    Route, Link
} from "react-router-dom";

// Import auth directly because most components need it
// Other Firebase libraries can be lazy-loaded as-needed
import 'firebase/auth';
import {LoadingSpinner} from "../display/LoadingSpinner";
import {GCUser, GCAdvisor} from "../user";
import {Stream} from "./Stream";
import {Thread} from "./Thread";
import {NewChart} from "./NewChart";
import {NewTemplate, NewTemplateButton} from "./NewTemplate";

const signOut = auth => auth.signOut().then(() => {console.log('signed out'); window.location.reload()});



const MyUserButton = (props) => {
    const firestore = useFirestore();
    const { data: user } = useUser();
    const auth = useAuth();
    const { status, data: signInCheckResult } = useSigninCheck();
    let myUser = new GCUser(user);
    const userProfile = useFirestoreDocDataOnce(myUser.storedDetailsDoc(firestore),{idField: 'uid'});

    if (status === 'loading') return <LoadingSpinner />;
    if(signInCheckResult.signedIn === true){
        if(!userProfile){
            myUser.storeUser(firestore);
        } else {
            myUser.initializeUser(userProfile);
        }
    }
    return (
        <>
        <button type="button" disabled={!user} className="float-right bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" onClick={() => signOut(auth)}>
           Sign Out {myUser?.name}
        </button>
        </>
    )
}
const Warning = () => {
    return (
        <div className="text-center text-gray-700 font-mono bg-red-300 font-medium">
            <p>For Testing Only -- Do Not Include Personally Identifiable Information</p>
        </div>
    )
}


const App = ({ firebaseConfig }: { firebaseConfig: { [key: string]: unknown } }) => {

    return (
        <Router>
            <div>
                <Warning />
            <FirebaseAppProvider firebaseConfig={firebaseConfig}>
                <Auth/>
                <Switch>
                    <Route exact path="/">
                        <div className="h-full w-screen flex flex-row">
                            <div id="sidenav" className="bg-white h-screen md:block shadow-xl px-3 w-30 md:w-60 lg:w-60 overflow-x-hidden transition-transform duration-300 ease-in-out">
                                <h1 className="text-2xl font-bold text-gray-900">My GoCharts</h1>
                                <ChartList />
                            </div>
                            <div className="max-w-7xl w-full py-6 px-4 sm:px-6 lg:px-8">
                                <MyUserButton />
                                <h1 className="text-3xl font-bold text-gray-900">Recent Updates</h1>
                                <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
                                    <Stream max={40}/>
                                </div>

                            </div>
                        </div>
                    </Route>
                    <Route path="/chart/:chartID">
                        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
                            <Chart />
                        </div>
                    </Route>
                    <Route path="/updates/:chartID">
                        <div className="h-full w-screen flex flex-row">
                            <div id="sidenav" className="bg-white h-screen md:block shadow-xl px-3 w-30 md:w-60 lg:w-60 overflow-x-hidden transition-transform duration-300 ease-in-out">
                                <h1 className="text-2xl font-bold text-gray-900">My GoCharts</h1>
                                <ChartList />
                                <NewTemplateButton />
                            </div>
                            <div className="max-w-7xl w-full sm:px-6 lg:px-8">
                                <ChartUpdates />
                            </div>
                        </div>
                    </Route>
                    <Route path="/newtemplate/:chartID">
                        <div className="h-full w-screen flex flex-row">
                            <div id="sidenav" className="bg-white h-screen md:block shadow-xl px-3 w-30 md:w-60 lg:w-60 overflow-x-hidden transition-transform duration-300 ease-in-out">
                                <h1 className="text-2xl font-bold text-gray-900">My GoCharts</h1>
                                <ChartList />
                            </div>
                            <div className="max-w-7xl w-full sm:px-6 lg:px-8">
                                <NewTemplate />
                            </div>
                        </div>
                    </Route>
                    <Route path="/text/chart/:chartID">
                        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
                            <TextChart />
                        </div>
                    </Route>
                    <Route path="/newchart">
                        <div className="h-full w-screen flex flex-row">
                            <div id="sidenav" className="bg-white h-screen md:block shadow-xl px-3 w-30 md:w-60 lg:w-60 overflow-x-hidden transition-transform duration-300 ease-in-out">
                                <h1 className="text-2xl font-bold text-gray-900">My GoCharts</h1>
                                <ChartList />
                            </div>
                            <div>
                                <NewChart />
                            </div>
                        </div>
                    </Route>
                    <Route path="/stream">
                        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
                            <MyUserButton />
                            <h1 className="text-3xl font-bold text-gray-900">My GoCharts - Recent Updates</h1>
                            <Link className="text-2xl text-blue-500" to="/">See List of Charts</Link>

                        </div>
                        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
                            <Stream max={500}/>
                        </div>
                    </Route>
                </Switch>
            </FirebaseAppProvider>
            </div>
        </Router>
    );
}

export default App
