import * as React from "react";
import {useState} from "react";
import {
    useFirestore,
    useFirestoreCollectionData,
    useFirestoreDocData,
    useFirestoreDocDataOnce,
    useUser
} from "reactfire";
import {GoItem, parseTextAsItem, updateTextAttrs, GoTitle, GoEntry, GoTeam , FIELDS} from "./GoItem";
import {Stoplight} from "./Stoplight";
import {LoadingSpinner} from "../display/LoadingSpinner";
import {GCUser, GCAdvisor} from "../user";
import {saveUpdateToTeam} from "./Team";

// for now we just overwrite an item when we edit it....not the best practice!
// but note that for an existing element changing an attribute automatically stores on server
// need to do similarly for textarea field...which right now requires a submit
function storeItem(item,attributes,items){
    let newitem = Object.fromEntries(item);
    let newattr = Object.fromEntries(attributes);
    newitem.attributes = newattr;
    let itemPromise = items.add(newitem); // expand out for collections of attributes....
    return itemPromise;
}


const NEWTOPIC = "Double-click to add a New Item";
const NEWENTRY = "Double-click to add a note / update";
export const NewEntry = ({ itemCollection, teamCollection }) => {
    const firestore = useFirestore();
    const [isEditing,updateState] = useState(false)
    const [title,resetTitle] = useState('');
    const [entry,resetEntry] = useState('');
    const [myStatus,setStatus] = useState('');
    const [myTeamStatus,setTeamStatus] = useState('');
    const [attrs,updateAttrs] = useState(new Map())
    const { data: user } = useUser();
    let myUser = new GCUser(user);
    const userProfile = useFirestoreDocDataOnce(myUser.storedDetailsDoc(firestore),{idField: 'uid'});
    myUser = myUser.initializeUser(userProfile);


    const saveEntry = () => {
        if(isEditing && title  ){
            return createItem()
        }
        resetTitle('');
        resetEntry('');
        setTeamStatus('');
        updateState(false);
    }
    const updateTitle = (val) => {
        resetTitle(val);
        updateState(true)
    }
    const updateEntry = (val) => {
        resetEntry(val);
        updateState(true)
    }
    const updateTeam = (team) => {
        //this should check that we have an array of initials...for now pass thru
        setTeamStatus(team);
        updateState(true);
    }

    // createItem and Create Update use similar machinery....both notify all subscribers ("accessors")
    const createItem = () => {
        //itemCollection.add({ docString: text}) // shorthand test
        let [newItem, attributes] = parseTextAsItem(entry);
        newItem.set('title',title);
        newItem.set('isReply',false);
        newItem.set('createdBy',myUser.email);
        newItem.set('color',myStatus);
        newItem.set('status',myTeamStatus);
        let currentDate = new Date();
        // now add newItem including attributes
        attributes = new Map([...attributes,...attrs]); // pick up any items added explicitly as attrs
        //make sure any updated items are in the docstring
        if(attrs && attrs.keys()) {
            newItem.set('_docString', updateTextAttrs(newItem.get('_docString'), attrs));
        }
        newItem.set('createdDate',currentDate)
        let newItemPromise = storeItem(newItem,attributes,itemCollection);
        //cleanup
        let itemid;
        newItemPromise.then((itemRef) => {
            itemid = itemRef.id;
            console.log('added item '+itemid)
            updateState(false);  //Reset
            resetTitle('');
            resetEntry('');
            setTeamStatus('');
            updateAttrs(new Map()); //Reset
        });
        // saveUpdateToTeam(newItem,teamCollection, firestore) //Don't need to do this as this point...wait until stoplight color chosen and status note added
        return itemid;
    }
    let startEditing = () => updateState(true);
    return (
        <tr className={isEditing ? "bg-gray-50" : "bg-gray-100"}>

            <td colSpan="2" className={isEditing && title ? "px-3 py-1" : "px-3 py-1 italic text-gray-500"}>
                <GoTitle title={title} updateFn={updateTitle} placeholder={NEWTOPIC} startFn={startEditing} />
            </td>
            <td className="px-3 italic text-gray-500">Click on a status light to update a topic</td>
            <td colspan="3" className="px-6 py-4 whitespace-nowrap text-center font-medium">
                {isEditing ? <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full"
                onClick={saveEntry}>
                    Save
                </button> : <></> }
            </td>
        </tr>
    );
}
const NewEntryOld = ({ itemCollection }) => {
    const firestore = useFirestore();
    const [entry, updateEntry] = useState('');
    const [attrs,updateAttrs] = useState(new Map())
    const createItem = (text) => {
        //itemCollection.add({ docString: text}) // shorthand test
        let [newItem, attributes] = parseTextAsItem(text);
        let currentDate = new Date();
        // now add newItem including attributes
        attributes = new Map([...attributes,...attrs]); // pick up any items added explicitly as attrs
        //make sure any updated items are in the docstring
        if(attrs && attrs.keys()) {
            newItem.set('_docString', updateTextAttrs(newItem.get('_docString'), attrs));
        }
        newItem.set('createdDate',currentDate)
        let newItemPromise = storeItem(newItem,attributes,itemCollection);
        //cleanup
        let itemid;
        newItemPromise.then((itemRef) => {
            itemid = itemRef.id;
            console.log('added item '+itemid)
            updateEntry('');  //Reset
            updateAttrs(new Map()); //Reset
        });
        return itemid;
    }

    return (
        <div className="mt-1 max-w-prose">
            <form onSubmit={(event) => {event.preventDefault();
                console.log('submitted element' + entry);
                createItem(entry)}}>
                <label htmlFor="notebox" className="block text-sm font-medium text-gray-700">
                    Add Entry
                </label>
                <textarea id="notebox" name="notebox" rows={3}
                          className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                          placeholder="Enter proposed #PlanElements here along with @role:person and #Date:YY-MM-DD to be completed"
                           value={entry} onChange={(event) => updateEntry(event.target.value)}/>
                <table className="min-w-full divide-y divide-gray-200">
                    <tbody className="bg-white divide-y divide-gray-200">
                    <NewAttr attrs={attrs}/>
                    </tbody>
                </table>

                <span className="flex justify-end">
                    <input type="submit" disabled={!entry}  value="Add Entry" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full"/>
                </span>
            </form>
        </div>
    );
}

export const createItem = (title,text,itemCollection) => {
    let [newItem, attributes] = parseTextAsItem(text);
    newItem.set(title,title);
    let currentDate = new Date();
    // now add newItem including attributes
    newItem.set('createdDate',currentDate)
    let newItemPromise = storeItem(newItem,attributes,itemCollection);
    //cleanup
    let itemid;
    newItemPromise.then((itemRef) => {
        itemid = itemRef.id;
        console.log('added item '+itemid)
    });
    return itemid;
}
export const NewEntryButton = ({chartID, itemCollection, selectedItem, callback}) => {
    const firestore = useFirestore();


    return (
        <div className="mt-1 ">
            <form onSubmit={(event) => {event.preventDefault();
                console.log('creating new element');
                createItem('New Item','Placeholder text for new item', itemCollection)}}>

                <span className="flex justify-end">
                    <input type="submit"  value="Add Entry" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full"/>
                </span>
            </form>
        </div>
    );
}


const EditEntry = ({ itemCollection, selectedItem, callback }) => {
    //This is outdated - all editing happens in place now.  TODO remove this item
    const [entry, updateEntry] = useState('');
    const [dirty, setDirty] = useState(false);
    const firestore = useFirestore();    //const attrRef = firestore.collection('charts').doc(chartID).collection('items').doc( selectedItem)
    const selectedRef = itemCollection.doc(selectedItem);
    const {itemstatus, data: item} = useFirestoreDocData(selectedRef, {idField: 'id'});
    console.log(selectedItem + " is selected...pre spinner status is " + itemstatus);
    if (selectedItem && itemstatus == 'loading') {return <LoadingSpinner />}
    if (!item) {return <LoadingSpinner />}
    console.log(selectedItem + " is selected...item is " + item);
    let attributes = item.attributes;
    if(!attributes){attributes = {}};
    let myattributes ;
    if (!myattributes) myattributes = new Map(Object.entries(attributes));

    //This stores current set of attributes to the database
    const updateAttribute = (key,value) => {
        myattributes.set(key,value);
        console.log('updating attr '+key+' value: '+value);
        //CAUTION this currently does NOT make sure _docString gets updated on the database until saveItem is pressed
        let updatedAttrs = Object.fromEntries(myattributes);
        let updatedItem={attributes: updatedAttrs};
        selectedRef.update(updatedItem)
            .then(()=>{
                console.log('updated item attrs item id:'+item.id + ' with value '+value);
                updateEntry(updateTextAttrs(entry,myattributes));
            })
            .catch((error)=>console.log('problem updating attrs for item it:'+item.id));
    }
    //TBD we need a way to avoid overwriting attributes if they are changed remotely..perhaps by making so only the owner
    //of an item can update that item...other folks would be making a copy of the item..or by making every save create an
    // immutable object...then updates simply create a new item with the old item acting as a kind of shadow...
    const updateItem = (text) => {
        let [newItem, addlAttrs] = parseTextAsItem(text);
        // now add newItem including attributes
        let fullAttrs = new Map([...addlAttrs,...myattributes]); // pick up any items added explicitly as attrs
        // storeItem(newItem,fullAttrs,itemCollection); //overwrites anything already there....careful with this
        let updatedItem = Object.fromEntries(newItem);
        updatedItem._docString = updateTextAttrs(newItem.get('_docString',myattributes))
        updatedItem.lastModified = new Date; //caution relies on client date function....
        fullAttrs = Object.fromEntries(fullAttrs);
        updatedItem.attributes = fullAttrs;
        selectedRef.update(updatedItem)
            .then(()=> {
                callback();
                setDirty(false)
            });
    }
    console.log('dirty is'+ dirty +' attr is'+ myattributes.keys());
    if(!dirty && entry != item._docString) updateEntry(item._docString);
    return (
        <div className="mt-1 max-w-prose">
            <form onSubmit={(event) => {
                event.preventDefault();
                console.log('submitted element' + entry);
                updateItem(entry);}}>
                <label htmlFor="notebox" className="block text-sm font-medium text-gray-700">
                    Update Entry
                </label>
                <textarea id="notebox" name="notebox" rows={3}
                          className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                          placeholder="Enter proposed #PlanElements here along with @role:person and #Date:YY-MM-DD to be completed"
                           value={entry} onChange={(event) => {setDirty(true); updateEntry(event.target.value);}}/>
                <table className="min-w-full divide-y divide-gray-200">
                    <tbody className="bg-white divide-y divide-gray-200">
                    {Array.from(myattributes.entries(),(([k,v]) => (
                        <Attr key={k} mykey={k} myvalue={v} attrSetter={updateAttribute}/>
                    )))}
                    </tbody>
                </table>
                <span className="flex justify-end">
                    <input type="submit" disabled={!dirty} value="Update Note" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full right-full"/>
                </span>
            </form>
        </div>
    );
}

export const ChartEntry = ({chartID, itemCollection, selectedItem, callback}) => {

    if (selectedItem) {return <EditEntry itemCollection={itemCollection} selectedItem={selectedItem} callback={callback} />}
    else { return <NewEntry itemCollection={itemCollection} /> }
}

const Attr = ({ mykey, myvalue, attrSetter }) => {
    const [val, updateVal] = useState(myvalue);
    return (
        <tr>
            <th>
                {mykey}
            </th>
            <td>
                <input type="text" value={val} onChange={(event) => {attrSetter(mykey, event.target.value);updateVal(event.target.value);}} />
            </td>
        </tr>
    )
}
const NewAttr = ({ attrs }) => {
    const [mykey, updateKey] = useState('');
    const [value, updateVal] = useState('');
    const changeKey = (newk) => {
        if (mykey && mykey != newk) {attrs.delete(mykey)}
        attrs.set(newk,value);
        updateKey(newk)
    }
    return (
        <tr>
            <th>
                <input type="text" value={mykey} placeholder="key"
                       onChange={(event) => changeKey(event.target.value)} />
            </th>
            <td>
                <input type="text" value={value} placeholder="value"
                       onChange={(event) => {attrs.set(mykey, event.target.value); updateVal(event.target.value)} }/>
            </td>
        </tr>
    )
}