import * as React from 'react';
import {useEffect, useState } from "react";
import TextareaAutosize from "react-textarea-autosize";

export const ChartBanner = ({chart, reference}) => {
    if(!chart.objectives || typeof(chart.objectives) != 'object'){
        chart.objectives = {situation: chart.objectives || '',
            finances: '',
            social: '',
            medical: ''
        };
    }
    if(chart.defaultView == 'history') {
        return <ChartHistory chart={chart} reference={reference}/>
    }
    if(chart.defaultView == 'timeline') {
        return <ChartTimeline chart={chart} reference={reference} />
    }
    return <ChartObjectives chart={chart} reference={reference} />

}

const ChartObjectives = ({chart, reference}) => {
    const [objectives, setObjectives] = useState(chart.objectives || {});
    const updateObjectives = (key: string, value: string) => {
        objectives[key] = value;
        setObjectives(objectives);
        chart.objectives = objectives;
        reference.set(chart,{update: true});
    }
    //create 2x2 grid with editable areas for each of the 4 critical systems - housing, financial, social, medical
    return <div className="grid grid-cols-2">
        <Objective bgcolor="bg-blue-200" header='Situation' objective={objectives?.situation || 'Living Situation'} updateObjective={(v) => updateObjectives('situation',v)} />
        <Objective bgcolor="bg-green-200" header='Finances' objective={objectives?.finances || 'Finances'} updateObjective={(v) => updateObjectives('finances',v)} />
        <Objective bgcolor="bg-yellow-200" header='Social' objective={objectives?.social || 'Social Situation'} updateObjective={(v) => updateObjectives('social',v)} />
        <Objective bgcolor="bg-purple-200" header='Medical' objective={objectives?.medical || 'Health Care'} updateObjective={(v) => updateObjectives('medical',v)} />
    </div>
}

const Objective = ({objective, updateObjective, bgcolor, header}) => {
    const [text,setText] = useState(objective);

    return <div className={bgcolor + ' text-left'}>
        <h3 className="text-center font-bold text-xl mb-0">{header}</h3>
        <Editable text={objective} placeholder={'Set your objectives'}>
            <TextareaAutosize minRows={2} autoFocus={true} className='min-w-full' value={text} placeholder={'Enter Objectives here'}
                      onBlur={(e) => updateObjective(e.target.value)}
                      onChange={(e) => setText(e.target.value)}
            />
        </Editable>
    </div>
        }

const ChartHistory = ({chart, reference}) => {
    return <span> History goes here </span>
}

const ChartTimeline = ({chart, reference}) => {
    return <span> History goes here </span>
}

const Editable = ({
                      text,
                      placeholder,
                      children,
                      ...props
                  }) => {
    const [isEditing, setEditing] = useState(false);
    return (
        <div {...props} className="m-1">
            {isEditing ? (
                <div
                    onBlur={() => setEditing(false)}
                >
                    {children}
                </div>
            ) : (
                <div className="whitespace-pre-wrap font-mono"
                    onDoubleClick={
                        () => setEditing(true)
                    }
                >
                    {text || placeholder || "edit content"}
                </div>
            )}
        </div>
    );
};
