import * as React from 'react';
import {useFirebaseApp, useFirestore, useFirestoreCollectionData, useFirestoreDocData} from 'reactfire';
import {LoadingSpinner} from "../display/LoadingSpinner";
import {AuthWrapper} from "../withoutSuspense/Auth";
import {useParams, useHistory} from "react-router-dom";
import {ChartEntry, NewEntryButton, NewEntry} from "./ChartEntry";
import {useEffect, useState } from "react";
import {GoItem} from "./GoItem"
import {ChartBanner} from "../withoutSuspense/ChartBanner";
import {TeamMembers} from "./Team";
import {Timeline} from "./Timeline"
import {ServiceSuggestions} from "./ServiceSuggestions";
import {Link} from "react-router-dom";
import {ClientInfo} from "./ClientInfo";


function ChartWrapper({ chartID }) {
    const firestore = useFirestore;
    const ref = firestore().doc('charts/' + chartID)
    const response = useFirestoreDocData(ref, {idField: 'id'});
    const history = useHistory();

    const { status, data: chart } = response;
    if (status === 'loading') {
        return <LoadingSpinner />;
    }

    return <div className="flex flex-col" id="chartTable">
        <h1 className="text-2xl font-bold text-gray-900">Client: {chart.client}
            <button type="button" className="text-lg float-right bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" onClick={() => history.push("/")}>
                Home
            </button>
        </h1>
        <div>
            <span className="px-3"> <b>DOB:</b> {chart.dob} </span>
            <span> <b>Contact:</b> {chart.contact} </span>
            <span> <b>Emergency Contact:</b> {chart.emergency} </span>
            <span> <b>Insurance:</b> {chart.insurance} </span>
        </div>

        <div id="mainWrapper" className="flex">
            <div className="flex-1">
                <div className="m-8">
                    <ChartBanner chart={chart} reference={ref} />
                </div>

                <ChartTable chart={chart} />
            </div>

        </div>

    </div>
}

//Sidebar for services
// <div id="services" className="w-0 md:w-96 flex-none ml-10">
//                 <ServiceSuggestions chartID={chartID} />
//             </div>

function UpdatesWrapper({ chartID }) {
    const firestore = useFirestore;
    const [showFull, setShowFull] = useState(false);
    const ref = firestore().doc('charts/' + chartID)
    const response = useFirestoreDocData(ref, {idField: 'id'});
    const history = useHistory();

    const { status, data: chart } = response;

    const itemsCollection = firestore().collection('charts/' + chartID + '/items') //This should be set up to auto update
    const teamCollection = firestore().collection('charts/' + chartID + '/accessors')
    if (status === 'loading') {
        return <LoadingSpinner />;
    }
    return <div className="flex flex-col" id="chartTable">
        <div className="text-center text-gray-700 bg-yellow-200 font-medium">
            <p>
                <span className="text-2xl font-bold text-gray-900">Client: {chart.client}</span>

                <button type="button" className="text-lg float-right bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" onClick={() => history.push("/")}>
                Home
                </button>
                <span className="py-2 px-4 float-right mr-9 w-7 h-7" onClick={() => setShowFull(!showFull)}>
                   {!showFull ? <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="gray" strokeWidth={2}>
                       <path strokeLinecap="round" strokeLinejoin="round" d="M19 13l-7 7-7-7m14-8l-7 7-7-7" />
                   </svg> : <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="gray" strokeWidth={2}>
                       <path strokeLinecap="round" strokeLinejoin="round" d="M5 11l7-7 7 7M5 19l7-7 7 7" />
                   </svg>}
                    </span>


            </p>
            {showFull ? <ClientInfo chart={chart} /> : <></>}

        </div>
        <div>
            <TeamMembers chart={chart} orgsOnly={false} show={showFull}/>
        </div>
        <div id="mainWrapper" className="flex">
            <div className="flex-1">
                {showFull ? <div className="m-8"> <ChartBanner chart={chart} reference={ref} /></div> : <></>}
                <ChartTable chart={chart} showFull={showFull}/>
            </div>
        </div>

    </div>
}

// <hr className="border-black border-2 mb-2"/>
// <h3 className="text-lg text-gray-900"><b>Primary Concern: </b> {chart.title} </h3>
//         <p><b>Notifications: </b>{chart.description}</p>

function ChartTable({ chart, showFull}) {
    const [selectedItemID, selectItem] = useState('');
    const chartID = chart.id;
    const firestore = useFirestore();
    const itemsCollection = firestore.collection('charts/' + chartID + '/items')  //Should be set up to auto update
    const teamCollection = firestore.collection('charts/' + chartID + '/accessors')

    //const itemsQuery = itemsCollection.orderBy('order',  'asc');  Need to keep track of order if we want to use it
    const itemsQuery = itemsCollection.orderBy('createdDate',  'desc');
    const { status, data: items } = useFirestoreCollectionData(itemsQuery, {idField: 'id'});
    //Not sure why we're doing the team query here instead of in the Team component....probably better practice to call the team
    //component with a setter function to lift the teamMembers up to this level...
    const teamQuery = teamCollection.orderBy('name',  'desc');
    const { teamStatus, data: teamMembers } = useFirestoreCollectionData(teamQuery, {idField: 'uid'});

    if (status === 'loading' || teamStatus === 'loading') {
        return <LoadingSpinner />;
    }
    let threads = {}; //keeps track of threaded conversations with updates....

    const unselectItem = () => {selectItem(undefined);}
    return (
        <div className="">
            {showFull ? <Timeline items={items} selectItem={selectItem} /> : <></>}
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="shadow border-b border-gray-200 sm:rounded-lg">

                        <table className="w-full max-w-full divide-y divide-gray-200 ">
                            <thead className="bg-gray-100">
                            <tr>

                                <th
                                    scope="col"
                                    className="w-44 max-w-44 px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                    Topic
                                </th>
                                <th scope="col"
                                    className="w-14 px-4 py-2 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                    Status
                                </th>

                                <th
                                    scope="col"
                                    className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                    Updates
                                </th>
                                <th scope="col"
                                    className="w-10 px-4 py-2 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >

                                </th>
                                <th
                                    scope="col"
                                    className="px-4 py-2 w-10 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >

                                </th>
                                <th
                                    scope="col"
                                    className="px-4 py-2 w-8 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                    Date

                                </th>

                            </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                            <NewEntry itemCollection={itemsCollection} teamCollection={teamCollection} />

                            {items.map((item) => (
                                <GoItem key={item.id} item={item}  selectedItemID={selectedItemID} chart={chart} selectItem={selectItem} items={itemsCollection} threads={threads} showAll={false} teamCollection={teamMembers}/>
                            ))}
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
        </div>
    )
}


export const Chart = (props) => {
    let { chartID } = useParams();
    if (!chartID && props.chartID) {chartID = props.chartID};
    return (
        <>
            <AuthWrapper fallback={<span>Sign in to view this chart</span>}>

                <div id={chartID}>
                    <ChartWrapper chartID={chartID}>
                    </ChartWrapper>
                </div>
            </AuthWrapper>
        </>
    );
};

export const ChartUpdates = (props) => {
    let { chartID } = useParams();
    if (!chartID && props.chartID) {chartID = props.chartID};
    return (
        <>
            <AuthWrapper fallback={<span>Sign in to view this chart</span>}>

                <div id={chartID}>
                    <UpdatesWrapper chartID={chartID}>
                    </UpdatesWrapper>
                </div>
            </AuthWrapper>
        </>
    );
};
//<button type="button" className="float-right bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" onClick={() => history.push("/")}>
//                         List Charts
//                     </button>
// <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
//
//                     <h4 className="hidden">Showing {chartID}</h4>
//                 </div>