import * as React from 'react';
import {useFirebaseApp, useFirestore, useFirestoreCollectionData, useFirestoreDocData} from 'reactfire';
import {LoadingSpinner} from "../display/LoadingSpinner";
import {AuthWrapper} from "../withoutSuspense/Auth";
import {useParams, useHistory} from "react-router-dom";
import {ChartEntry, NewEntryButton, NewEntry} from "./ChartEntry";
import {useEffect, useState } from "react";
import {GoItem} from "./GoItem"
import {ChartBanner} from "../withoutSuspense/ChartBanner";
import {TeamMembers} from "./Team";


function TextChartWrapper({ chartID }) {
    const firestore = useFirestore();
    const ref = firestore.doc('charts/' + chartID)
    const response = useFirestoreDocData(ref);
    const { status, data: chart } = response;

    const itemsCollection = firestore.collection('charts/' + chartID + '/items')
    const itemsQuery = itemsCollection.orderBy('createdDate',  'desc');
    const { istatus, data: items } = useFirestoreCollectionData(itemsQuery, {idField: 'id'});

    if (status === 'loading' || istatus === 'loading') {
        return <LoadingSpinner/>;
    }
    const objectives = chart.objectives ? chart.objectives : {};
    const okeys = Object.keys(objectives);
    const myitems = items;
    const mykeys = ['finances','situation','medical','social'];
    if(!myitems) {
        console.log('No Items!');
        return <div>No Items!!</div>
    }
    // for some reason this fails... {mykeys.map((k) => (<TextObjective label={k} objective={objectives[k]} key={k} />))}
    return <div className="flex flex-col" id="chartTable">
        <h1 className="text-2xl font-bold ">Client: {chart.client}</h1>
        <div>
            <span> <b>DOB:</b> {chart.dob}</span>
            <span> <b>Contact Info:</b> {chart.contact}</span>
            <span> <b>Emergency Contact Info:</b> {chart.emergency}</span>
            <span> <b>Insurance:</b> {chart.insurance}</span>
        </div>
        <h3 className="text-lg  "><b>Primary Concern: </b> {chart.title} </h3>
        <p><b>Notifications: </b>{chart.description}</p>
        <div className="m-8">
            <TextObjective label='Finances' objective={objectives.finances} />
            <TextObjective label='Living situation' objective={objectives.situation} />
            <TextObjective label='Medical' objective={objectives.medical} />
            <TextObjective label='Social' objective={objectives.social} />
        </div>
        {items.map((item) => (
            <TextItem key={item.id} item={item} />
        ))}
    </div>
}
function TextObjective({label, objective}) {
    return <div>
        <h2>{label}</h2>
        <span className="whitespace-pre-wrap">
            {objective}
        </span>
    </div>
}
function TextItem({item}) {
    return <>
        <TextItemHeader item={item} />
        <TextItemBody item={item} />
    </>
}
function TextItemHeader({item}) {
    return <div className="mt-5">
        <h3 className="bold text-2xl">{item.title}</h3>
        <span className="italic text-sm">
            Created by: {item.createdBy} on {item.createdDate?.toDate().toDateString()}; Last Modified: {item.lastModified?.toDate().toDateString()}
        </span>
    </div>
}

function TextItemBody({item}) {
    return <div className="whitespace-pre-wrap">
        {item.desc}
    </div>
};

export const TextChart = (props) => {
    let { chartID } = useParams();
    if (!chartID && props.chartID) {chartID = props.chartID};
    const history = useHistory();
    return (
        <>
            <AuthWrapper fallback={<span>Sign in to view this chart</span>}>
                <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
                    <button type="button" className="float-right bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" onClick={() => history.push("/")}>
                        List Charts
                    </button>
                    <h4 className="hidden">Showing {chartID}</h4>
                </div>
                <div>
                    <TextChartWrapper chartID={chartID}>
                    </TextChartWrapper>
                </div>
            </AuthWrapper>
        </>
    );
};