import * as React from 'react';

export const ClientInfo = ({chart}) => {

    return <div>
        <span className="px-3"> <b>DOB:</b> {chart.dob} </span>
        <span> <b>Contact:</b> {chart.contact} </span>
        <span> <b>Emergency Contact:</b> {chart.emergency} </span>
        <span> <b>Insurance:</b> {chart.insurance} </span>
    </div>
}
