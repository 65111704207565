import * as React from 'react';
import {useEffect, useState } from "react";
import {getColor} from "../components/Stoplight";
import firebase from 'firebase/app';

type Timestamp = firebase.firestore.Timestamp;
const MIN = -600;
const MAX = 400;
const LINES = [-600, -200, -30, 30, 200, 400]//[-200, -14, 14, 100]
const LABELS = [['Past Years',-500],['Prev Year', -150],['Current month',-14],['Next Year',80], ['Future', 220]]

function scaleDate(d : Date , reference = new Date()) {
    //scale the date with a "fisheye view / cartesion distortion"....with today being 0, -800 being max left, +200 max right
    //for now just use cuts at -200 days and +100 days
    let diff = reference.getTime() - d.getTime() //d.toMillis();
    diff = -diff / (1000 * 24 * 3600); // convert to number of days
    return scaledDays(diff)
}
function scaledDays(diff) {
    let result = diff;
    if (diff > 100) {
        //result = Math.log(diff)
        result = 100 + (diff - 100) //* 100 / diff ;
    } else if (diff < -200) {
        //result = Math.log(-diff)
        result = -200 +  (diff + 200) //* 200 / diff ;
    }
    if (diff < 14 && diff > -14)
    {
        result = diff * 3;
    } else {
        result = result + 14 * 3 * Math.sign(diff)
    }
    if (result < MIN) result = MIN;
    if (result > MAX) result = MAX;
    return result;
}

export const Timeline = ({items, selectItem}) => {


    return <svg id="timeline" width="100%" height="80px" viewBox="-600 0 1000 50" preserveAspectRatio="none" className="mb-2">

        <rect fillOpacity={0.1} fill="yellow" x={MIN} y={0} width={1000} height={10} />
        <rect fillOpacity={0.1} fill="light-grey" x={MIN} y={10} width={1000} height={10} />
        <rect fillOpacity={0.1} fill="orange" x={MIN} y={20} width={1000} height={10} />
        <rect fillOpacity={0.1} fill="green" x={MIN} y={30} width={1000} height={10} />

        {LINES.map((line) => (
            <line key={line} x1={scaledDays(line)} y1={0} x2={scaledDays(line)} y2={40} opacity="0.5" stroke="grey" />
        ))}
        {LABELS.map(([txt,ctr]) => (
            <text key={ctr} x={scaledDays(ctr)} y={40}  fill="grey" opacity="0.5" fontSize="smaller">{txt}</text>
        ))}
        {items.map((item) => (
            <TimelineElement key={item.id} item={item} selectItem={selectItem} />
        ))}
    </svg>
}
function asDate(myDate){
    return new Date(myDate);
}
const TimelineElement = ({item, selectItem}) => {
    const mySelectItem = (itemID) => {
        selectItem(itemID);
    }
    let myDate = item.dueDate ? asDate(item.dueDate) : item.createdDate?.toDate();

    if (!myDate) return <></>;
    return <circle cx={scaleDate(myDate)} cy="25" r={5} fill={myColor(item.color)} fillOpacity={1} onClick={()=>mySelectItem(item.id)}/>
}

function myColor(c){
    let result =getColor(c);
    if(!c) result = "slategrey";
    return result;
}