import * as React from 'react';
import { useEffect, useState, Key } from 'react';
import { preloadFirestore, useFirebaseApp, useFirestore, useFirestoreCollectionData, useFirestoreDoc, useFirestoreDocDataOnce, useUser } from 'reactfire';
import firebase from "firebase";
import {COLORS, Stoplight} from "./Stoplight";
import {TeamMember, TeamMemberBlank, saveUpdateToTeam} from "./Team";
import {GoItem, parseTextAsItem, updateTextAttrs, GoTitle, GoEntry, GoTeam} from "./GoItem";
import {GCUser} from "../user";


export type Update = {
    id?: Key;
    title: string;
    entry: string;
    itemID: string;
    color: string;
    author?: string;
    authorID?: string;
    clientID?: string;
    clientName?: string;
    createdDate: firebase.firestore.Timestamp;
    recipient?: string;
    prevItem: Map<string,string>;
}

export function createNewUpdate(item,entry) {

    let newUpdate = {'title': item.title, 'entry': entry, 'itemID': item.id, 'color': item.color,
        'createdDate': firebase.firestore.Timestamp.fromDate( new Date())
    }
    if (!entry && item.desc ) newUpdate.entry = item.desc;
    return newUpdate;
}
function storeUpdate(update,attributes,items){

    let itemPromise = items.add(update); // expand out for collections of attributes....
    //attribute list ignored for now...included for future
    return itemPromise;
}

export function updateItem(item,attr){
    //Sets the attributions on item....
    //for now just hides the item

    if(attr.has('delete') || attr.has('hide')) {
        item.hidden = true;
    }
}

export const DraftUpdate = ({item, draft, setIsUpdating, saveItem}) => {
    const [entry, updateEntry] = useState(draft.entry);
    const [color, updateColor] = useState(draft.color);
    const [recipient, setRecipient] = useState(draft.recipient)
    const { data: user } = useUser();
    let myUser = new GCUser(user);
    draft.author = myUser.email;
    const updateTeam = (team) => {
        draft.recipient = team;
    }
    const saveUpdate = (text) => {
        draft.entry = text;
        updateEntry(text);
        item.color = draft.color;
        item.currentStatus = draft;
        saveItem();
        setIsUpdating(false);
    }
    return <tr>
        <td className="w-4 px-3 py-3 whitespace-nowrap">
            <Stoplight color={draft.color} setColor={ (c) => {draft.color = c; updateColor(c)}} colors={COLORS} />

        </td>
        <td className="w-6 px-3 py-1 whitespace-nowrap">
            <GoTeam status={draft} updateFn={updateTeam}/>
        </td>
        <td className=" py-1 ">
            <EditUpdate title={draft.entry} updateFn={saveUpdate}  />
        </td>

        <td className="text-sm text-gray-500">

        </td>

        <td className="px-3 py-1 whitespace-nowrap text-center text-sm font-medium text-gray-500"  > Noop </td>

    </tr>
}

export const ShowUpdates = ({item, threads, items,team, chart}) => {
    const firestore = useFirestore();
    //if (!threads[item.id]){  //Caution right now we will show updates as individual item AND as threaded item....
    if (false && item.replyTo){ //for now show everything in order without threading....if threading then this needs changed TBD
        return <></>
    }
    return <>

        <UpdateItem item={item} update={item.currentUpdate} items={items} team={team} chart={chart} />

    </>
}

//Above should include list of updates if threading
// {threads[item.id].map((update) => (
//             <Update key={update.id} item={item} update={update} />
//         ))}


const UpdateItem = ({item, update, items, team, chart}) => {
    const firestore = useFirestore();
    const { data: user } = useUser();
    let myUser = new GCUser(user);
    const userProfile = useFirestoreDocDataOnce(myUser.storedDetailsDoc(firestore),{idField: 'uid'});
    myUser = myUser.initializeUser(userProfile);
    const createUpdate = (update) => {
        if(!update) return;
        //TBD resolve chartID and clientID to have consistent naming throughout
        let newReply={'title': item.title, 'desc': update, 'isReply': true, 'replyTo': item.id, 'threadID': item.id, 'createdDate': new Date(), 'createdBy': myUser.email, 'clientID': chart.id, 'clientName':chart.client};
        if(item.threadID){
            newReply.threadID = item.threadID;
        }
        let newAttrs={}
        let updatePromise=storeUpdate(newReply,newAttrs,items);
        let updateID;
        updatePromise.then((itemRef) => {
            updateID = itemRef.id;
            console.log('added item '+updateID)
            item.currentUpdate = ''; //Reset
        });
        saveUpdateToTeam(newReply, team, firestore);
    }

    return <tr>
        <td className="text-sm">add reply</td>
        <td></td>
        <td></td>

        <td className="px-3 py-1 whitespace-nowrap text-center text-sm font-medium text-gray-500">
            <EditUpdate title={item.currentUpdate}
                     updateFn={createUpdate}
                     placeholder="double click to add update"
            />

        </td>
        <td></td>
        <td></td>
    </tr>
}

const Update = (item, update) => {
    if (!update) return <></>
    return <tr>
        <td>{update.id}</td>
        <td>{update.title}</td>

        <td>{update.desc}</td>
        <td></td>
        <td>blank update</td>
    </tr>
}

export const EditUpdate = ({title, updateFn, placeholder='New Update'}) => {
    const [isEditing, setEditing] = useState(false);
    const [text, updateText] = useState(title);
    if(!isEditing && title != text) updateText(title); //in case data changes outside of our control
    return (
        <>
            {isEditing ? (
                <>
                    <input type='text' autoFocus={true} className='bg-gray-100 w-3/4' value={text}
                       onChange={e => updateText(e.target.value)}
                    />
                    &nbsp; <button type="button" onClick={(e)=> {updateFn(text); setEditing(false)}} title="Send">Send</button>
                </>
            ) : (
                <span
                    onDoubleClick={
                        () => setEditing(true)
                    }
                >
                    {text ? text : placeholder}
                </span>
            )}
        </>
    );
};