// @ts-ignore
import React from 'react';
// @ts-ignore
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App';
import reportWebVitals from './reportWebVitals';
// import { FirebaseAppProvider } from 'reactfire';

// import { App as NonConcurrentModeApp } from './withoutSuspense/App';
import './styles.pcss';
import {onBFCacheRestore} from "web-vitals/dist/modules/lib/onBFCacheRestore";

const firebaseConfig = {
  apiKey: "AIzaSyBbLFRGqoij_pMSGaJgUmr6OTqoLotucJQ",
  authDomain: "gchart-d2.firebaseapp.com",
  projectId: "gchart-d2",
  storageBucket: "gchart-d2.appspot.com",
  messagingSenderId: "245912236481",
  appId: "1:245912236481:web:9dda883079bd29ac03bf40",
  measurementId: "G-RYYRHRD8MC"
};

const rootElement = document.getElementById('root');
if (!rootElement) {
  throw new Error('Could not find root element');
}

// let RUN_EXAMPLES = true;
// if (RUN_EXAMPLES === false) {
// ReactDOM.render(<NonConcurrentModeApp firebaseConfig={firebaseConfig} />, rootElement);

ReactDOM.render(<App firebaseConfig={firebaseConfig} />, rootElement);

//} else {
  // https://reactjs.org/docs/concurrent-mode-adoption.html#enabling-concurrent-mode
  //ReactDOM.unstable_createRoot(rootElement).render(<ConcurrentModeApp firebaseConfig={firebaseConfig} />);

// Do my little test
//  ReactDOM.render(
//    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
//   <App />
//  </FirebaseAppProvider>,
//  document.getElementById('root')
// );
// }


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();



