import * as React from 'react';
import { useEffect, useState } from 'react';
import { preloadFirestore, useFirebaseApp, useFirestore, useFirestoreCollectionData, useFirestoreDoc, useFirestoreDocDataOnce, useUser } from 'reactfire';
import firebase from "firebase";
import { Stoplight, COLORS } from "./Stoplight";
import {TeamMember, TeamMemberBlank, saveUpdateToTeam} from "./Team";
import {createNewUpdate, DraftUpdate, ShowUpdates, Update, updateItem} from "./ChartUpdate";
import {GCUser} from "../user";
import {LoadingSpinner} from "../display/LoadingSpinner";


const hideItem = (item) => {
    item.hidden = true;
    // caller must save item to permanently hide
}
const shiftItem = (item) => {
    //this is deprecated
    item.indented = item.indented ? false : true;
    // caller must do this saveItem();
}

const DRAFT = "Double click to add update";
// Note the item specific function below should be pulled up and use item as arg to avoid creating new functions for each render

//TBD change all functions to save items in the same way...with "new items" not having a reference to previous item
//"updates" are items that reference previous topic (have smae title) and when displayed the most recent is shown
//scratch that ... "updates" are now only status updates. Items remain as having only one instantiation....when an item is updated there
//should be a status update that contains the effective "change" -- e.g. archives the old version of the item description, title, etc
//status updates should basically only occur when a stoplight changes color. and the pattern should be to leave an associated note
export const GoItem = ({ item, selectedItemID, selectItem, items, threads, showAll, teamCollection, chart }) => {
    const selectedRef = items.doc(item.id);
    const [isUpdating, setIsUpdating] = useState(false)
    const [draftUpdate, setDraftUpdate] = useState("");
    const [statusLight, setStatusLight] = useState(item.color);
    const [showHistory, setShowHistory] = useState(false);
    //const [oldItem, setOldItem] = useState(new Map());
    const oldItem = Object.assign({}, item)
    const { data: user } = useUser();
    const firestore = useFirestore();
    const updatesCollection = selectedRef.collection('updates')  //Should be set up to auto update

    //as of 2022-5 replies are deprecated in favor of updates
    const startUpdate = (color = "") => {
        if(color) setStatusLight(color);
        if(!isUpdating){
            let newUpdate = "";
            setIsUpdating(true);
            setDraftUpdate(newUpdate);
            //setOldItem(new Map(Object.entries(item)));
        }

    }
    const updateDraft = (text) => {
        setDraftUpdate(text);
    }
    const saveUpdate = (update) => {
        let myUser = new GCUser(user);
        update.author = myUser.email;
        update.authorID = myUser.uid;
        update.clientID = chart.id;
        update.clientName = chart.client;
        // if (!oldItem) alert('no prev item defined')
        update.prevItem = oldItem;
        //sets the current update for this item on all team members and adds it to the list of updates for this item
        //alert("TBD save update here to all team plus the collection of updates on item")
        saveUpdateToTeam(update,teamCollection,firestore)
        //now save as an update on the item
        return updatesCollection.add(update);
        setIsUpdating(false);
    }

    const saveItem = () => {
        item.lastModified = new Date; //caution relies on client date function....
        if(item.color != statusLight){
            item.color = statusLight;
        }
        if(isUpdating) {
            if(draftUpdate && (draftUpdate != DRAFT)){
                item.desc = draftUpdate;
            }
            const newUpdate = createNewUpdate(item,draftUpdate);
            saveUpdate(newUpdate);
            let teamStatus = item.hasOwnProperty('teamStatus') && item.teamStatus ? item.teamStatus : {};
            if (newUpdate.hasOwnProperty('author') && newUpdate['author']) {
                teamStatus[newUpdate['author']] = {'color': newUpdate.color, 'entry': newUpdate.entry, 'lastModified': newUpdate.createdDate}
                item.teamStatus = teamStatus;
            }
            let iCount = item.hasOwnProperty('updateCount') && item.updateCount ? item.updateCount : 0;
            item.updateCount = iCount + 1;
            setIsUpdating(false);
        }
        //TBD update more of the item here to include the latest update.
        //Maybe wrap up the update text into an update
        let [parsedItem, parsedAttr] = parseTextAsItem(draftUpdate);
        updateItem(item,parsedAttr);
        return selectedRef.update(item); //use .then() if we need to do anything after updating ..or catch error
        //catch error here
    }

    const updateField = (newAttr) => {selectedRef.update(newAttr)};
    const updateEntryDoc = (desc) => {
        item.desc = desc; //should parse item here
        if(desc.startsWith('\t')){
            shiftItem(item);
        }
        saveItem();
    }
    const updateTitle = (title) => {
        //Caution this makes it very easy to delete a title then save it by accident after moving cursor (on blur action does an update)
        item.title = title;
        saveItem(); //use .then() if we need to do anything after updating ..or catch error
    }

    const updateDueDate = (date) => {
        if(date == 'hide'){
            hideItem(item);
        }
        item.dueDate = date;
        saveItem();
    }
   // for now show all items without threading
    // if (threads.hasOwnProperty(threadID)){
    //     if(threads[threadID][0].id!=item.id){ //If this is not the first item in the thread then don't show it
    //         threads[threadID].push(item);
    //         return <tr><td>thinks duplicate {item.id} not equal {threads[threadID][0].id}</td></tr>;
    //     }
    // } else {
    //     threads[threadID]=[];
    //     threads[threadID].push(item)
    // }
    let rowStyle = "py-3";
    let textStyle = "text-sm text-gray-500";
    if(isUpdating) textStyle += "italic "
    if (item.id == selectedItemID) rowStyle += " bg-blue-200" ;
    else if(!showAll && item.hidden) return (<></>);  //Need to have a way to show all
    return (
        <>
            <tr  className={rowStyle}>

                <td className="w-6 px-3 py-1 whitespace-nowrap font-bold">
                    <GoTitle title={item.title} updateFn={updateTitle} />
                </td>
                <td className="w-4 px-3 py-3 whitespace-nowrap">
                    <Stoplight color={isUpdating ? statusLight : item.color } setColor={ startUpdate } colors={COLORS} /></td>
                <td className={textStyle}>
                    <GoEntry desc={isUpdating ? draftUpdate : item.desc}
                             updateFn={updateDraft}
                             startFn = {startUpdate}
                             placeholder={isUpdating && item.desc ? item.desc : "add status note here"}

                    />
                </td>
                <td className="w-6 px-3 py-1 whitespace-nowrap" onClick={() => {setShowHistory(!showHistory)}}>
                    {!showHistory ? <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="gray" strokeWidth={2}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M19 13l-7 7-7-7m14-8l-7 7-7-7" />
                    </svg> : <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="gray" strokeWidth={2}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M5 11l7-7 7 7M5 19l7-7 7 7" />
                    </svg>}
                </td>
                <td className="text-right px-2">{isUpdating ? <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-2 rounded-full" onClick={saveItem}>Save</button> : <></>}</td>
                <td className="px-3 py-1 whitespace-nowrap text-right text-sm font-medium text-gray-500">
                    <GoTitle title={item.dueDate ? item.dueDate : item.createdDate?.toDate().toLocaleDateString()}
                             updateFn={updateDueDate}
                             placeholder="MM/DD/YY"
                    />

                </td>

            </tr>
            {showHistory ? <ListUpdates selectedRef={selectedRef} /> : <></>}
            {!showHistory && item.hasOwnProperty('teamStatus') && item.teamStatus ? <ListTeamStatus item={item}/> : <></> }
        </>
    )
}

const ListUpdates = ({selectedRef}) => {
    const firestore = useFirestore();
    const updatesCollection = selectedRef.collection('updates')  //Should be set up to auto update
    const {status: updatesStatus, data: updates} = useFirestoreCollectionData(updatesCollection.orderBy('createdDate','desc'))
    if(updatesStatus =='loading') return <LoadingSpinner/>
    let rowStyle = "py-3";
    let textStyle = "text-sm text-gray-500 text-right";
    return <>
        {updates.map((update : Update) => (
            <tr className={rowStyle}>
                <td className={textStyle}>{update.author}</td>
                <td className="w-4 px-3 py-3 whitespace-nowrap"><Stoplight color={update.color} /></td>
                <td className="text-sm text-gray-500">{update.entry}</td>
                <td> </td><td> </td>
                <td className="px-3 text-sm text-right text-gray-500">{update.createdDate?.toDate().toLocaleDateString()}</td>
            </tr>
        ))}
    </>
}
// {isUpdating ? <DraftUpdate item={item} draft={draftUpdate} setIsUpdating={setIsUpdating} saveItem={saveItem} /> : <CurrentStatus item={item} startUpdate={startUpdate} /> }


const ListTeamStatus = ({item}) => {
    return <>
        {Object.keys(item.teamStatus).map((k) => (
            (item.teamStatus[k].color != item.color) ? <TeamStatusRow memberID={k} memberStatus={item.teamStatus[k]} /> : <></>
        ))
        }
    </>
}
const TeamStatusRow = ({memberID, memberStatus}) => {
    let rowStyle = "py-3";
    let textStyle = "text-sm text-gray-500 text-right";

    return <tr className={rowStyle}>
        <td className={textStyle}>{memberID}</td>
        <td className="w-4 px-3 py-3 whitespace-nowrap"><Stoplight color={memberStatus.color}/>
        </td>
        <td className="text-sm text-gray-500">{memberStatus.entry}</td>
        <td></td><td> </td>
        <td className="px-3 text-sm text-right text-gray-500">{memberStatus.lastModified.toDate().toLocaleDateString()}</td>
    </tr>
}

const CurrentStatus = ({item, startUpdate}) => {

    return <tr>
        <td className="w-4 px-3 py-3 whitespace-nowrap">
            <Stoplight color={item.color} setColor={ (c) => startUpdate(c)} colors={COLORS} />

        </td>
        <td className="w-6 px-3 py-1 whitespace-nowrap">

        </td>
        <td className=" py-1 ">

        </td>

        <td className="text-sm text-gray-500">

        </td>

        <td className="px-3 py-1 whitespace-nowrap text-center text-sm font-medium text-gray-500" onDoubleClick={() => startUpdate('')} onClick={() => startUpdate('')}> update </td>
    </tr>
}


const ActionButtons = ({item}) => {
    return (<>
        <button className="text-indigo-600 hover:text-indigo-900" onClick={() => shiftItem(item)}>
        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path d="M4.555 5.168A1 1 0 003 6v8a1 1 0 001.555.832L10 11.202V14a1 1 0 001.555.832l6-4a1 1 0 000-1.664l-6-4A1 1 0 0010 6v2.798l-5.445-3.63z" />
        </svg>
    </button>
    &nbsp;
    <button onDoubleClick={() => hideItem(item)}>
        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M3.707 2.293a1 1 0 00-1.414 1.414l14 14a1 1 0 001.414-1.414l-1.473-1.473A10.014 10.014 0 0019.542 10C18.268 5.943 14.478 3 10 3a9.958 9.958 0 00-4.512 1.074l-1.78-1.781zm4.261 4.26l1.514 1.515a2.003 2.003 0 012.45 2.45l1.514 1.514a4 4 0 00-5.478-5.478z" clipRule="evenodd" />
            <path d="M12.454 16.697L9.75 13.992a4 4 0 01-3.742-3.741L2.335 6.578A9.98 9.98 0 00.458 10c1.274 4.057 5.065 7 9.542 7 .847 0 1.669-.105 2.454-.303z" />
        </svg>
    </button>
        </>
    )
}
export const GoTeam = ({status, updateFn}) => {
    const [isEditing, setEditing] = useState(false);
    const [team, updateTeam] = useState(status);
    return (
        <>
            {isEditing ? (
                <input width='2em' type='text' autoFocus={true} className='bg-gray-100 w-6' value={team}
                       onChange={e => updateTeam(e.target.value)}
                       onBlur={(e) => {
                           updateFn(e.target.value);
                           setEditing(false);
                       }}
                />
            ) : (
                <span
                    onDoubleClick={
                        () => setEditing(true)
                    }
                >
                    {team ? <TeamMember initials={team} color='bg-blue-400'/> : <TeamMemberBlank/>}
                </span>
            )}
        </>
    )
}
function notifyStartEditing(){};

export const GoTitle = ({title, updateFn, placeholder='New Topic', startFn = notifyStartEditing}) => {
    const [isEditing, setEditing] = useState(false);
    const [text, updateText] = useState(title);
    if(!isEditing && title != text) updateText(title); //in case data changes outside of our control
    return (
        <>
            {isEditing ? (
                <input type='text' autoFocus={true} className='bg-gray-100' value={text}
                       onChange={e => updateText(e.target.value)}
                    onBlur={(e) => {
                        updateFn(e.target.value);
                        setEditing(false);
                    }}
                />
            ) : (
                <span
                    onDoubleClick={
                        () => {
                            setEditing(true);
                            startFn();
                        }
                    }
                >
                    {text ? text : placeholder}
                </span>
            )}
        </>
    );
};
export const GoEntry = ({desc, updateFn, placeholder = 'Double click to add note', startFn = notifyStartEditing}) => {
    const [isEditing, setEditing] = useState(false);
    const [text,updateText] = useState(desc);
    if(!isEditing && desc != text) updateText(desc); // in case changes outside our control
    return (
        <>
            {isEditing ? (
                <textarea className="text-sm text-gray-900 min-w-full bg-gray-100"
                          autoFocus={true} value={text}
                          onChange={(e)=> updateText(e.target.value)}
                          onBlur={(e) => {
                           updateFn(e.target.value);
                           setEditing(false);
                       }}
                />
            ) : (
                <span className={text ? "text-sm text-gray-900" : "italic text-sm text-gray-600"}
                    onDoubleClick={
                        () => {setEditing(true); startFn();}
                    }
                >
                    {text ? text : placeholder}
                </span>
            )}
        </>
    );
};


const replaceSpaces = (value) => value.replace(/\s/g,'_');

export function updateTextAttrs(docString,attrs){
    //makes sure that all attributes are reflected properly in the text....any spaces in keys or attributes get turned into underscores or vice versa so that we always have singe word for #key:value represented in the docString
    let myattrs = new Map(attrs);
    let result : string[] = [];
    const keyRE = /\b#(\w*):(\S*)\b/g;
    let lastMatch = 0;
    for (let m of docString.matchAll(keyRE)){

        if(myattrs.has(m[1])){
            result.push(docString.slice(lastMatch,m.index));
            result.push(`#${m[1]}:${replaceSpaces(attrs.get(m[1]))}`);
            lastMatch = m.index + m[0].length;
            myattrs.delete(m[1]);
        } else {
            //do nothing ... we'll add the existing string in the next match or at the end
        }
    }
    result.push(docString.slice(lastMatch)); //add all remaining text
    myattrs.forEach((value,key) => result.push(` #${key}:${replaceSpaces(value)}`));
    return result.join('');
}


export function parseTextAsItem(text) {
    // returns a list of key:value pairs including a sublist of attributes derived from text
    // optional template is a function that return a default listing of key:value pairs along with other stuff
    let result = new Map();
    let attributes = new Map();
    result.set('_docString',text);
    //let [title, desc] = getTitleDesc(text);
    //result.set('title',title);
    let desc = text;
    result.set('desc',desc);
    getAttributes(desc,attributes);
    //move things like color to main item
    //should iterate through...for now just do it direclty for color
    if(attributes.has('color')){
        result.set('color',attributes.get('color'));
        attributes.delete('color')
    }
    return ([result,attributes]);
}
function getTitleDesc(text) {
    text = text.trim();
    let title = '';
    let desc = '';
    let split = text.indexOf('\n');
    if (split > 0) {
        title = text.slice(0, split);
        desc = text.slice(split + 1);
    } else {
        //title is the first 10 chars plus
        split = text.indexOf(' ', 10);
        if (split > 20) split = 15;
        title = text.slice(0, split)
        desc = text;
    }
    return ([title, desc]);
}

function getAttributes(text,attrs){
    let words = text.split(' ');
    words.map((word) => getKV(word,attrs));
    return attrs;
}
function getKV(word,result){
    let k=''; let v='';
    let word1 = word.slice(1);
    if(word.startsWith('#')){
        [k,v]=parseHash(word1);
    } else if (word.startsWith('@')) {
        [k,v]=parseAt(word1);
    } else {
        return
    }
    if(v){
        if(k) {result.set(k,v)}
        else {
            if (!result.has('unknown')) {
                result.set('unknown',[])
            }
            result.get('unknown').push(v);
        }
    }
}

function parseHash(word: string): string[] {
    console.log('\n this is the word: '+word);
    let [key,value] = splitSemi(word)
    if(!key && value in COLORS) {key = 'color'};
    console.log('\n this is the k,v: '+key+','+value);

    return ([key,value])
    //need to fill in keys if missing based on value....
}

function parseAt(word: string): string[] {
    let [key,value] = splitSemi(word)
    return ([key,value])
}


function splitSemi(word: string): string[]{

    let split = word.indexOf(':');
    let key = ''; let value = '';
    if (split > 0) {
        key = word.slice(0,split);
        value = word.slice(split+1);
    } else {
        value = word;
    }
    return ([key,value]);
}