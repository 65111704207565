import * as React from 'react';
import { useEffect, useState } from 'react';
import { preloadFirestore, useFirebaseApp, useFirestore, useFirestoreCollectionData, useFirestoreDocData, useFirestoreDocDataOnce, useUser } from 'reactfire';
import {LoadingSpinner} from "../display/LoadingSpinner";
import firebase from "firebase";
import {AuthWrapper} from "../withoutSuspense/Auth";
import {NewChart} from "./NewChart";
import {Link} from "react-router-dom";
import {GCUser, GCAdvisor} from "../user";
import {GoTitle} from "./GoItem"
import {TeamMembers} from "./Team";
import {Stoplight, COLORS} from "./Stoplight";

export function ChartListing(props) {
    const firestore = useFirestore();
    const { data: user } = useUser();
    let myUser = new GCUser(user);
    const userProfile = useFirestoreDocDataOnce(myUser.storedDetailsDoc(firestore),{idField: 'uid'});
    myUser = myUser.initializeUser(userProfile);
    const itemsCollection = myUser.myChartsCollection(firestore);
    const itemsQuery = itemsCollection.orderBy('createdDate',  'desc'); //probably should be lastModified date
    const { status, data: charts } = useFirestoreCollectionData(itemsQuery, {idField: 'id'});


    if (status === 'loading' ) {
        return <LoadingSpinner />;
    }
    const updateField = (chartid,field,value) => {
        const chartRef = itemsCollection.doc(chartid);
        return chartRef.update(field,value) //should use the promise and make sure the update happens...
    }
    return (
        <div className="flex flex-col">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                            <tr>
                                <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                    Client
                                </th>
                                <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                    Advisor
                                </th>
                                <th
                                    scope="col"
                                    colSpan="2"
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                    Status
                                </th>
                                <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                    Organizations
                                </th>

                                <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                    Open
                                </th>
                                <th scope="col" className="relative px-6 py-3">
                                    <span className="sr-only">View</span>
                                </th>
                            </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                            {charts.map((chart) => (
                                <tr key={chart.id}>
                                    <td className="px-6 py-4 ">
                                        <div className="flex items-center">
                                            <Link to={`/updates/${chart.id}`} className="text-indigo-600 hover:text-indigo-900">
                                                {chart.client}
                                            </Link>
                                        </div>
                                    </td>
                                    <td className="px-6 py-4 ">
                                        {chart.advisor}
                                    </td>
                                    <td className="w-4 px-3 py-3 whitespace-nowrap">

                                        <Stoplight color={chart.statusColor} setColor={ (c) => updateField( chart.id, 'statusColor', c)} colors={COLORS} />

                                    </td>
                                    <td className="px-6 py-4 ">
                                        <GoTitle title={chart.description}
                                                 updateFn={(val) => updateField(chart.id,'description', val)} placeholder="Dbl click to edit" />
                                    </td>

                                    <td className="px-6 py-4 ">
                                        <TeamMembers chartID={chart.id} orgsOnly="True" />

                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                        <Link to={`/chart/${chart.id}`} className="text-indigo-600 hover:text-indigo-900">
                                            Open
                                        </Link> /
                                        <Link to={`/text/chart/${chart.id}`} className="text-indigo-600 hover:text-indigo-900">
                                            Text
                                        </Link>

                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="mt-10 ">
                        <NewChart />
                    </div>
                </div>
            </div>
        </div>
    )
}

export function ChartListingSidebar(props) {
    const firestore = useFirestore();
    const { data: user } = useUser();
    let myUser = new GCUser(user);
    const userProfile = useFirestoreDocDataOnce(myUser.storedDetailsDoc(firestore),{idField: 'uid'});
    myUser = myUser.initializeUser(userProfile);
    const itemsCollection = myUser.myChartsCollection(firestore);
    let itemsQuery = itemsCollection.orderBy('subscribedOn',  'desc'); //probably should be lastModified date
    if(myUser.email === 'wolff@sages.com') itemsQuery = itemsCollection.orderBy('createdDate',  'desc'); //probably should be lastModified date
    const { status, data: charts } = useFirestoreCollectionData(itemsQuery, {idField: 'id'});
    const invitesCollection = myUser.myChartInvites(firestore);
    const invitesQuery = invitesCollection.orderBy('createdDate',  'desc'); //probably should be lastModified date
    const { status: iStatus, data: invites } = useFirestoreCollectionData(invitesQuery, {idField: 'id'});

    if (status === 'loading' || iStatus === 'loading') {
        return <LoadingSpinner />;
    }

    let subs={};
    charts.map((c)=> subs[c.id]=c);
    let newInvites = invites.filter( c => !subs.hasOwnProperty(c.id));
    return (
        <div className="flex flex-col">
                            {charts.map((chart) => (

                                        <div className="flex ">
                                            <Link to={`/updates/${chart.id}`} className="font-medium text-gray-700 py-2 px-2 hover:bg-indigo-600 hover:text-white hover:text-base rounded-md transition duration-150 ease-in-out">
                                                {chart.client}
                                            </Link>
                                        </div>
                                ))}
            {newInvites.map((chart) => (

                <div className="flex ">
                    <Link to={`/updates/${chart.id}`} className="font-medium text-gray-700 py-2 px-2 hover:bg-indigo-600 hover:text-white hover:text-base rounded-md transition duration-150 ease-in-out">
                        {chart.client}
                    </Link>
                </div>
            ))}

            <div className="mt-10 ">
                <Link to="/newchart" className="lg:font-medium text-gray-700 py-2 px-2 hover:bg-indigo-600 hover:text-white hover:text-base rounded-md transition duration-150 ease-in-out">
                + New Chart
                </Link>

            </div>
        </div>

    )
}
export const ChartList = (props) => {
    const firebaseApp = useFirebaseApp();
/*
    const [database, setDatabase] = useState(undefined);
    useEffect(() => {
        preloadFirestore({
            firebaseApp: firebaseApp,
            setup: async firestore => {
                await firestore().enablePersistence();
                setDatabase(firestore());
            }
        });
    }, []);

    if (!database) {
        return <div className="flex flex-col"><p>Waiting for database connection....</p></div>;
    }
*/

    return (
        <>
            <AuthWrapper fallback={<span>Please Sign In via Google</span>}>
                <ChartListingSidebar>
                </ChartListingSidebar>
            </AuthWrapper>
        </>
    );
};