import {useState} from "react";
import * as React from "react";

function notifyStartEditing(){};
export const EditBox = ({txt, updateFn, placeholder='New Txt', startFn = notifyStartEditing}) => {
    const [isEditing, setEditing] = useState(false);
    const [text, updateText] = useState(txt);
    // if(!isEditing && txt != text) updateText(txt); //in case data changes outside of our control
    return (
        <>
            {isEditing ? (
                <input type='text' autoFocus={true} className='bg-gray-100' value={text}
                       onChange={e => updateText(e.target.value)}
                       onBlur={(e) => {
                           updateFn(e.target.value);
                           setEditing(false);
                       }}
                />
            ) : (
                <span
                    onDoubleClick={
                        () => {
                            setEditing(true);
                            startFn();
                        }
                    }
                >
                    {text ? text : placeholder}
                </span>
            )}
        </>
    );
};