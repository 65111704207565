import * as React from 'react';
import {AuthWrapper} from "../withoutSuspense/Auth";
import {
    ObservableStatus,
    useFirestore,
    useFirestoreCollectionData,
    useFirestoreDocData,
    useFirestoreDocDataOnce,
    useUser
} from "reactfire";
import {LoadingSpinner} from "../display/LoadingSpinner";
import {GCUser} from "../user";
import {Stoplight, COLORS} from "./Stoplight";
import {Link} from "react-router-dom";
import {EditUpdate, Update} from "./ChartUpdate";
import {useState} from "react";
import {saveUpdateToTeam} from "./Team";
import {AddUpdate} from "./AddUpdate";
import {stat} from "fs";

export const Stream = ({max}) => {
    return (
        <>
            <AuthWrapper fallback={<span>Sign in to view your stream</span>}>

                <div id='auth'>
                    <StreamWrapper max={max}/>

                </div>
            </AuthWrapper>
        </>
    );
}

// For each chart that a person is following (e.g. they are on the team)
// there is an icon indicating the person, on the icon is 3 circles RYG with numbers inside indicating
// the number of topics of each color
// the top 2 urgent (red) topics with the most recent updates are shown
// the ordering of charts is based on recency of comments
// need to update individual charts so that we cache the top items in each along with the counts
// TBD for user maintain a list of the charts they subscribe to.  For each user keep a list with their charts & current status
// including counts of lights and advisor


function StreamWrapper({max}){
    const firestore = useFirestore();
    const { data: user } = useUser();
    let myUser = new GCUser(user);
    // const ref = firestore.doc('updates/test')
    // const response = useFirestoreDocData(ref);
    // const {status, data: userStreamInfo}: ObservableStatus<unknown> = response;
    const updatesCollection = myUser.myUpdates(firestore)
    const itemsQuery = updatesCollection.orderBy('createdDate',  'desc'); //probably should be lastModified date
    const { status, data: updates1 } = useFirestoreCollectionData(itemsQuery, {idField: 'id'});
    let updates;
    updates = updates1 as unknown as Update[];
    if (status === 'loading') {
        return <LoadingSpinner/>
    }



    return <><p>Stream for {myUser.uid} {myUser.email}</p>
    <div className="flex flex-col" id="stream">

        <div className="w-full ">

            {updates.map((item : Update, i ) => (
                (i < max) ? <StreamItem key={item.id} item={item} mUser={myUser} /> : <></>
            ))}

        </div>
    </div>
    </>
}

export const StreamItem = ({ item, mUser }) => {
    const [isUpdating, setIsUpdating] = useState(false)
    const [statusLight, setStatusLight] = useState(item.color);

    const startUpdate = (color = "") => {
        if(color) setStatusLight(color);
        if(!isUpdating){
            setIsUpdating(true);
        }
    }

    if (isUpdating){
        return <AddUpdate item={item} mUser={mUser} color={statusLight} setIsUpdating={setIsUpdating} />
    }
    return (
        <div className="max-w-7xl">
            <div className="w-full py-1 px-6 bg-white shadow-lg rounded-lg my-5 flex">
                <div className="flex-none justify-center md:justify-center py-2">
                    <Stoplight color={item.color} colors={COLORS} setColor={startUpdate}></Stoplight>
                    <div className="py-2">
                        <Link to={`/updates/${item.clientID}`} className="text-xl font-medium text-indigo-600 hover:text-indigo-900 "> {(item.clientName)} </Link>
                    </div>
                </div>
                <div className="flex-auto px-5 py-1">
                    <h2 className="text-gray-800 text-xl font-semibold">{item.title}</h2>
                    <p className="mt-2 text-gray-600">{item.entry}</p>

                    <div className="flex justify-end mt-4">

                        <i>{item.author} </i>
                    </div>
                </div>
            </div>


        </div>
    )
}

function StreamWrapperTable(){
    const firestore = useFirestore();
    const { data: user } = useUser();
    let myUser = new GCUser(user);
    // const ref = firestore.doc('updates/test')
    // const response = useFirestoreDocData(ref);
    // const {status, data: userStreamInfo}: ObservableStatus<unknown> = response;
    const updatesCollection = myUser.myUpdates(firestore)
    const itemsQuery = updatesCollection.orderBy('createdDate',  'desc'); //probably should be lastModified date
    const { status, data: updates1 } = useFirestoreCollectionData(itemsQuery, {idField: 'id'});
    let updates;
    updates = updates1 as unknown as Update[];
    if (status === 'loading') {
        return <LoadingSpinner/>
    }



    return <div className="flex flex-col" id="stream">
        <p>Stream for {myUser.uid} {myUser.email}</p>
        <table className="w-full max-w-full dividy-y divide-gray-200">
            <tbody>
            {updates.map((item : Update ) => (
                <StreamItem key={item.id} item={item} mUser={myUser} />

                ))}
            </tbody>
        </table>
    </div>
}



export const StreamItemTable = ({ item, mUser }) => {
    let rowStyle = "py-3";
    let doNothing = ()=>{};
    let colors = ['red','yellow', 'green'];
    return (
        <tr  className={rowStyle}>
            <td className="w-4 px-3 py-3 whitespace-nowrap">

                <Link to={`/updates/${item.clientID}`} className="text-indigo-600 hover:text-indigo-900"> {(item.clientName)} </Link>
            </td>
            <td className="w-6 px-3 py-1 whitespace-nowrap">
                <i className="bg-red-500">{item.countRed} </i>
                <i className="bg-yellow-500">{item.countYellow} </i>
                <i className="bg-green-500">{item.countGreen} </i>
            </td>
            <td>
                <Stoplight color={item.status} colors={colors} setColor={doNothing}></Stoplight>
            </td>
            <td className="px-3 py-1 text-gray-500">
                <b>{item.title} </b>
                <i>{item.desc}</i>
            </td>

            <td className="px-3 py-1 text-gray-500">
                <b>owner: {item.topicOwner} </b>
                <i>proposer: {item.createdBy} </i>
                <b>recipients: {item.recipients} </b>
                <i>suggestedStatus: {item.proposedStatus} </i>
                {(mUser.canUpdateChart(item))?'Confirm':'not your job'}
            </td>

        </tr>
    )
}